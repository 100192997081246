const home = () => import('@/views/home/index.vue')
const kefu = () => import('@/views/kefu/index.vue')
const wxKefu = () => import('@/views/wx-kefu/index.vue')
const share = () => import('@/views/share/index.vue')
const yiyuan = () => import('@/views/yiyuan/index.vue')
const zushaoxia = () => import('@/views/zushaoxia/index.vue')
const zushaoxiayiyuan = () => import('@/views/zushaoxiayiyuan/index.vue')
const drawback = () => import('@/views/drawback/index.vue')
const dingdongzuji = () => import('@/views/dingdongzuji/index.vue')
const swhz = () => import('@/views/swhz/index.vue')
const rentalRaiders = () => import('@/views/rental-raiders/index.vue')
const download = () => import('@/views/download/index.vue')
const smartDownload = () => import('@/views/smartDownload/index.vue')
const pdf = () => import('@/views/pdf/index.vue')
const mianyaquanyi = () => import('@/views/mianyaquanyi/index.vue')
const agreement = () => import('@/views/agreement/index.vue')
const mianyaquanyi_paid = () => import('@/views/mianyaquanyi_paid/index.vue')
const mianyaquanyi_paid_zhizuji = () =>
  import('@/views/mianyaquanyi_paid_zhizuji/index.vue')
const mianyaquanyi_merchant = () =>
  import('@/views/mianyaquanyi_merchant/index.vue')
const secondhand = () => import('@/views/secondhand/index.vue')
const oneyuan = () => import('@/views/oneyuan/index.vue')
const oneyuan_landing = () => import('@/views/oneyuan_landing/index.vue')
const oneyuan_zhizuji = () => import('@/views/oneyuan_zhizuji/index.vue')
const mianya_zhizuji = () => import('@/views/mianya_zhizuji/index.vue')
const oneyuan_ddzuwu = () => import('@/views/oneyuan_ddzuwu/index.vue')
const returnUrl = () => import('@/views/returnUrl/index.vue')
const downLoadApp = () => import('@/views/downLoadApp/index.vue')
const contractSigning = () => import('@/views/contractSigning/index.vue')
const contractSignings = () => import('@/views/contractSignings/index.vue')
const mianshen = () => import('@/views/mianshen/index.vue')
const merchantDetail = () => import('@/views/merchant-detail/index.vue')
const merchantList = () => import('@/views/merchant-list/index.vue')

const routes = [
  // {
  //   path: '/',
  //   name: 'zushaoxiayiyuan',
  //   component: zushaoxiayiyuan,
  //   meta: { title: '租少侠一元租机', keepAlive: false },
  // },
  {
    path: '/',
    component: home,
    meta: { title: '深圳租机宝供应链服务有限责任公司', keepAlive: false },
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: { title: '租机宝', keepAlive: false },
  },
  {
    path: '/kefu',
    name: 'kefu',
    component: kefu,
    meta: { title: '在线客服', keepAlive: false },
  },
  {
    path: '/wx-kefu',
    name: 'wx-kefu',
    component: wxKefu,
    meta: { title: '微信客服', keepAlive: false },
  },
  {
    path: '/share',
    name: 'share',
    component: share,
    meta: { title: 'APP分享', keepAlive: false },
  },
  {
    path: '/yiyuan',
    name: 'yiyuan',
    component: yiyuan,
    meta: { title: '一元租机', keepAlive: false },
  },
  {
    path: '/dingdongzuji',
    name: 'dingdongzuji',
    component: dingdongzuji,
    meta: { title: '叮咚租机', keepAlive: false },
  },
  {
    path: '/zushaoxia',
    name: 'zushaoxia',
    component: zushaoxia,
    meta: { title: '租少侠', keepAlive: false },
  },
  {
    path: '/zushaoxiayiyuan',
    name: 'zushaoxiayiyuan',
    component: zushaoxiayiyuan,
    meta: { title: '租少侠一元租机', keepAlive: false },
  },
  {
    path: '/drawback',
    name: 'drawback',
    component: drawback,
    meta: { title: 'VIP退款申请', keepAlive: false },
  },
  {
    path: '/swhz',
    name: 'swhz',
    component: swhz,
    meta: { title: '商务合作', keepAlive: false },
  },
  {
    path: '/rental-raiders',
    name: 'rental-raiders',
    component: rentalRaiders,
    meta: { title: '租赁攻略', keepAlive: false },
  },
  {
    path: '/download',
    name: 'download',
    component: download,
    meta: { title: '租机宝APP下载', keepAlive: false },
  },
  {
    path: '/smart-download',
    name: 'smartDownload',
    component: smartDownload,
    meta: { title: '租机宝APP下载', keepAlive: false },
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: pdf,
    meta: { title: 'pdf预览', keepAlive: false },
  },
  {
    path: '/mianyaquanyi',
    name: 'mianyaquanyi',
    component: mianyaquanyi,
    meta: { title: '开通VIP会员', keepAlive: false },
  },
  {
    path: '/mianyaquanyi_paid',
    name: 'mianyaquanyi_paid',
    component: mianyaquanyi_paid,
    meta: { title: '免押专区', keepAlive: false },
  },
  {
    path: '/mianyaquanyi_paid_zhizuji',
    name: 'mianyaquanyi_paid_zhizuji',
    component: mianyaquanyi_paid_zhizuji,
    meta: { title: '免押专区', keepAlive: false },
  },
  {
    path: '/mianyaquanyi_merchant',
    name: 'mianyaquanyi_merchant',
    component: mianyaquanyi_merchant,
    meta: { title: '免押专区', keepAlive: false },
  },
  {
    path: '/secondhand',
    name: 'secondhand',
    component: secondhand,
    meta: { title: '二手严选', keepAlive: false },
  },
  {
    path: '/oneyuan',
    name: 'oneyuan',
    component: oneyuan,
    meta: { title: '首月1元', keepAlive: false },
  },
  {
    path: '/oneyuan_landing',
    name: 'oneyuan_landing',
    component: oneyuan_landing,
    meta: { title: '首月1元', keepAlive: false },
  },
  {
    path: '/oneyuan_zhizuji',
    name: 'oneyuan_zhizuji',
    component: oneyuan_zhizuji,
    meta: { title: '首月1元', keepAlive: false },
  },
  {
    path: '/mianya_zhizuji',
    name: 'mianya_zhizuji',
    component: mianya_zhizuji,
    meta: { title: '免押租机', keepAlive: false },
  },
  {
    path: '/oneyuan_ddzuwu',
    name: 'oneyuan_ddzuwu',
    component: oneyuan_ddzuwu,
    meta: { title: '全免押金租手机', keepAlive: false },
  },
  {
    path: '/mianshen',
    name: 'mianshen',
    component: mianshen,
    meta: { title: '租手机交押金包过', keepAlive: false },
  },
  {
    path: '/returnUrl',
    name: 'returnUrl',
    component: returnUrl,
    meta: { title: 'returnUrl', keepAlive: false },
  },
  {
    path: '/downLoadApp',
    name: 'downLoadApp',
    component: downLoadApp,
    meta: { title: 'downLoadApp', keepAlive: false },
  },
  {
    path: '/contractSigning',
    name: 'contractSigning',
    component: contractSigning,
    meta: { title: '合同签署', keepAlive: false },
  },
  {
    path: '/contractSignings',
    name: 'contractSignings',
    component: contractSignings,
    meta: { title: '实名认证', keepAlive: false },
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: agreement,
    meta: { title: '用户协议', keepAlive: false },
  },
  {
    path: '/merchantDetail',
    name: 'merchantDetail',
    component: merchantDetail,
    meta: { title: '商家详情', keepAlive: false },
  },
  {
    path: '/merchantList',
    name: 'merchantList',
    component: merchantList,
    meta: { title: '商家列表', keepAlive: false },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'home' },
  },
]

export default routes
